// import logo from './logo.svg';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Home from './com/Home';
import Video from '../src/com/Video';
function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
      
        <Route path="/" element={<Home/>} />
        <Route path="/video" element={<Video />} />
      </Routes>
    </Router>
     
    </div>
  );
}

export default App;
