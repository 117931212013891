import React, { useRef, useState ,useEffect} from "react";
import vdo from "../img/sample.mp4"; // Import the video from the `img` folder
import "../style/video.css"; // Import CSS for styling

const Video = () => {
  const videoRef = useRef(null); // Reference to the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track play/pause
  const [showContactButton, setShowContactButton] = useState(false);

  useEffect(() => {
    // Set a timer to show the "Contact Now" button after 30 seconds
    const timer = setTimeout(() => {
      setShowContactButton(true);
    }, 300000); // 30 seconds in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause(); // Pause the video
    } else {
      video.play(); // Play the video
    }
    setIsPlaying(!isPlaying); // Toggle the state
  };

  return (
    <div className="video-container">
      <h1>Watch this video till the end</h1>
      
      {/* Video Player */}
      <div className="video-wrapper">
        <video ref={videoRef} className="custom-video"  autoFocus>
          <source src={vdo} type="video/mp4"  />
          Your browser does not support the video tag.
        </video>

        {/* Custom Controls */}
        <div className="video-controls">
          <button onClick={handlePlayPause} className="play-pause-button">
            {isPlaying ? "Pause" : "Play"}
          </button>
         

        </div>
        <p>schedule a free consultation call <br/>to grow your Business</p>
        <small>(Button will be enabled at the middle of the video)</small><br/>
        {/* "Contact Now" Button */}
        {showContactButton && (
        <button className="contact">SCHEDULE <br/>A CALL HERE</button>
      )}
      </div>
        

    </div>
  );
};

export default Video;
