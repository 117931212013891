import React, { useState } from "react";
import "../style/Home.css";
import PopupForm from "./PopupForm";
import thumbnail from "../img/Thumbnail.jpg";

const Home = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="promo-container">
      {/* Heading */}
      <h1 className="promo-heading">
        For Business Owners{" "}
        <span className="highlight">Solopreneurs,</span> Freelancers,{" "}
        <span className="highlight">Self-Employed</span> Coaches{" "}
        <span className="highlight">Consultants Only</span>.
      </h1>
      <p className="promo-subheading">
        (increase your sales and revenue 2X using Our Marketing strategies
        within 45 Days-or You don't pay)
      </p>

      {/* Main Content */}
      <div className="promo-content">
        <div className="promo-image-container">
          <img src={thumbnail} alt="Digital Entrepreneur" className="promo-image" onClick={togglePopup}/>
        </div>

        <div className="promo-text">
          <h2 className="promo-text-heading">
            Watch this Short and Crisp Masterclass to
          </h2>
          <ul className="promo-list">
            <li>Learn Proven, Creative Marketing Strategies</li>
            <li>Learn to attract<br/>high-quality leads</li>
            <li>Convert those leads into life long customers/clients</li>
            <li>Live with the Freedom of Time, Money & Travel</li>
          </ul>
          <h2 className="promo-text-heading">
        Build your presonal Brand and dominate your industry
          </h2>
          <button className="promo-button" onClick={togglePopup}>
            Watch Now For FREE
          </button>
        </div>
      </div>

      {/* Popup Form */}
      <PopupForm isVisible={isPopupVisible} onClose={togglePopup} />
    </div>
  );
};

export default Home;
