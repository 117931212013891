import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import "../style/PopupForm.css"; // Import a separate CSS file for the popup

const PopupForm = ({ isVisible, onClose }) => {
  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",
    city: "",
  });
  const [isLoading, setIsLoading] = useState(false); // State for loading animation

  const navigate = useNavigate(); // Initialize the navigation hook
  
  if (!isVisible) return null;

  const { name, number, email, city } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start the loading animation
    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/gunasekaran/google_sheets/rXFCStxyYkIckThv?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [name, number, email, city, new Date().toLocaleString()],
          ]),
        }
      );
      await response.json();
      setData({ name: "", number: "", email: "", city: "" });
      setIsLoading(false); // Stop the loading animation
      window.alert("Successfully submitted!"); // Show success message
      navigate("/video"); // Navigate to the Video component
    } catch (err) {
      setIsLoading(false); // Stop the loading animation in case of an error
      console.log(err);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Watch Now For FREE!</h2>
        {isLoading ? (
          <div className="loading-spinner">Loading...</div> // Show spinner
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Mobile Number:
              <input
                type="tel"
                placeholder="Enter your mobile number"
                name="number"
                value={number}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              City:
              <input
                type="text"
                placeholder="Enter your city"
                name="city"
                value={city}
                onChange={handleChange}
                required
              />
            </label>
            <button type="submit" className="form-submit-button">
              Submit
            </button>
            <button type="button" className="form-cancel-button" onClick={onClose}>
              Close
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PopupForm;
